<template>
  <div>
    <base-section
      id="enduser-account"
      space="16"
    >
      <v-container>
        <kdw-Statusbar-card
          v-model="snackbarUpdate"
          :snackbar-timeout="snackbarTimeout"
          :snackbar-message="snackbarMessage"
        />
        <v-row justify="space-between">
          <v-col
            cols="12"
            md="12"
          >
            <base-info-card :title="$t('AccountEU.mainTitle')" />
            <v-card
              class="rounded-xl"
            >
              <v-system-bar
                color="jean"
                class="grey--text text--lighten-3"
              >
                <v-icon
                  class="ml-3 grey--text text--lighten-3"
                >
                  mdi-card-account-details-outline
                </v-icon>
                <span
                  class="font-weight-bold ml-2"
                >
                  {{ $t('AccountEU.mainTitle').toUpperCase() }}
                </span>
              </v-system-bar>
              <v-card-text
                class="mt-2"
              >
                <v-form>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <kdw-text-field
                        id="IDfirstname"
                        v-model="myPerson.firstname"
                        :label="$t('identity.firstname') + $t('meta.star')"
                        prepend-icon="mdi-account-circle"
                        clearable
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <kdw-text-field
                        id="IDlastname"
                        v-model="myPerson.lastname"
                        :label="$t('identity.lastname') + $t('meta.star')"
                        prepend-icon="mdi-account-circle"
                        clearable
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                    >
                      <kdw-text-field
                        id="IDaddress1"
                        v-model="myPerson.address1"
                        :label="$t('identity.address1') + $t('meta.star')"
                        prepend-icon="mdi-map-marker"
                        clearable
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                    >
                      <kdw-text-field
                        id="IDaddress2"
                        v-model="myPerson.address2"
                        :label="$t('identity.address2')"
                        prepend-icon="mdi-map-marker"
                        clearable
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="3"
                    >
                      <kdw-text-field
                        id="IDpostcode"
                        v-model="myPerson.postcode"
                        :label="$t('identity.postcode') + $t('meta.star')"
                        prepend-icon="mdi-map-marker"
                        clearable
                        @input="postCodeChanged"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="4"
                    >
                      <v-autocomplete
                        v-if="myPerson.postcode.length === 5"
                        id="IDtownname"
                        v-model="myPerson.endUserPostcodecityId"
                        :items="towns"
                        item-text="townName"
                        item-value="id"
                        auto-select-first
                        dense
                        prepend-icon="mdi-map-marker"
                        :label="$t('identity.city') + $t('meta.star')"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="5"
                    >
                      <v-autocomplete
                        v-if="countries.length != 0"
                        id="IDcountry"
                        v-model="myPerson.endUserCountryId"
                        :items="countries"
                        item-text="name"
                        item-value="id"
                        auto-select-first
                        dense
                        prepend-icon="mdi-map-marker"
                        :label="$t('identity.country') + $t('meta.star')"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="4"
                    >
                      <kdw-vue-tel-input
                        select-id="IDphone"
                        v-model="myPerson.phone"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="8"
                    >
                      <kdw-text-field
                        id="IDmail"
                        v-model="myPerson.email"
                        :label="$t('identity.mail') + $t('meta.star')"
                        :rules="rulesEMail"
                        prepend-icon="mdi-email"
                        clearable
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-checkbox
                      id="IDconsent"
                      class="ml-3"
                      v-model="myPerson.consent"
                    >
                      <template
                        v-slot:label
                      >
                        <p
                          class="ml-2 mt-5"
                          v-html="legalSentence + $t('meta.star')"
                        />
                      </template>
                    </v-checkbox>
                  </v-row>
                  <v-row
                    class="mr-1 mb-1"
                    justify="end"
                  >
                    <kdw-btn
                      id="IDsubmit"
                      @click="submit"
                    >
                      {{ $t('button.validateQuestion') }}
                    </kdw-btn>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </base-section>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator'
  import { updateEndUser as updateEndUserMutation } from '@/graphql/mutations'
  import { getEndUser } from '@/graphql/queries'
  import { API, Logger } from 'aws-amplify'
  // import { deleteSearchTableContent, populateSearchTable } from '@/services/Search'
  import { fetchCountries } from '@/services/Country'
  import { fetchTownfromPostalCode /* , createPostalData */ } from '@/services/PostalCode'
  import { getUserSub } from '@/services/User'
  // import { sendEmail } from '@/services/Mail'
  import { buildLegalSentenceTagBased } from '@/helpers/GenericFunctions'

  const logger = new Logger('EndUserAccount')

  @Component({ name: 'EndUserMyAccount' })
  class EndUserMyAccount extends Vue {
    snackbarUpdate = false
    snackbarMessage = ''
    snackbarTimeout = 2000
    myPerson = { id: '', firstname: '', lastname: '', address1: '', address2: '', postcode: '', endUserPostcodecityId: '', phone: '', email: '', consent: false, endUserCountryId: '' }
    countries = []
    towns = []
    legalSentence = ''

    rulesEMail = [
      v => !!(v || '').match(/@/) || this.$i18n.t('AccountSP.mailFormat'),
    ]

    alertSnackBar (messagekey, snackbarTimeout) {
      this.snackbarMessage = this.$i18n.t(messagekey)
      this.snackbarUpdate = true
      this.snackbarTimeout = snackbarTimeout
    }

    // click on the submit button
    submit () {
      if (!this.myPerson.id || !this.myPerson.firstname || !this.myPerson.lastname || !this.myPerson.email) return
      this.updateEndUserData(this.myPerson)
    }

    postCodeChanged () {
      // console.log(this.myPerson.postcode)
      if (this.myPerson.postcode.length === 5) {
        fetchTownfromPostalCode(this.myPerson.postcode, this.towns)
      }
    }

    // Before loading the form but also called during the form exit!!!!
    created () {
      // We are building the legal sentence
      buildLegalSentenceTagBased(this.$i18n.t('AccountEU.consent1'), this.$i18n.t('AccountEU.consent2'), this.$i18n.t('AccountEU.consent3'), this.$i18n.t('AccountEU.consent4'))
        .then((sentence) => {
          // console.log('sentence:')
          // console.log(sentence)
          this.legalSentence = sentence
        })
      // console.log('created in acconut')
      this.loadEndUserData(this.myPerson, this.towns)
        .then(() => {
          // console.log('data of end user loaded')
        })
      fetchCountries(this.countries)
        .then(() => {
          // console.log('countries loaded')
        })
    }

    async loadEndUserData (myEndUser, towns) {
      // const user = await Auth.currentAuthenticatedUser()
      // console.log('loadEndUserData start')
      // console.log('end User: ' + await getUserSub())
      try {
        const apiDataEndUser = await API.graphql({ query: getEndUser, variables: { id: await getUserSub() }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
        // console.log(apiDataEndUser)
        const EndUser = apiDataEndUser.data.getEndUser
        // console.log(EndUser)
        if (EndUser.length === 0) {
          this.alertSnackBar('AccountEU.alertEUNoFound', 3000)
          // alert(this.$i18n.t('AccountEU.alertEUNoFound'))
          return
        }
        // change the data by reference (can not pass the object)
        myEndUser.firstname = EndUser.firstname
        myEndUser.lastname = EndUser.lastname
        myEndUser.address1 = EndUser.address1
        myEndUser.address2 = EndUser.address2
        myEndUser.postcode = EndUser.postcode
        // console.log(EndUser.postcodecity)
        if (EndUser.postcodecity === null) {
          // console.log('found null')
          myEndUser.endUserPostcodecityId = ''
        } else {
          myEndUser.endUserPostcodecityId = EndUser.postcodecity.id
        }
        // myEndUser.city = EndUser.city
        myEndUser.phone = EndUser.phone
        myEndUser.email = EndUser.email
        myEndUser.consent = EndUser.consent
        myEndUser.id = EndUser.id
        if (EndUser.country !== null) {
          myEndUser.endUserCountryId = EndUser.country.id
        }
        if (myEndUser.postcode.length === 5) {
          // console.log(towns)
          await fetchTownfromPostalCode(myEndUser.postcode, towns)
        }
        // console.log('loadEndUserData end')
      } catch (err) {
        logger.error(err, err.stack)
        this.alertSnackBar('AccountEU.alertEULoadDataIssue', 3000)
        // alert(this.$i18n.t('AccountEU.alertEULoadDataIssue'))
      }
    }

    async updateEndUserData (endUserToUpdate) {
      try {
        // console.log('endUserToUpdate : ')
        // console.log(endUserToUpdate)
        // await CheckPostalCodeAndTownMatch(endUserToUpdate.postcode, endUserToUpdate.city)
        // console.log('updateEndUserData')
        // console.log(endUserToUpdate)
        await API.graphql({ query: updateEndUserMutation, variables: { input: endUserToUpdate }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
        this.alertSnackBar('AccountEU.alertEUSuccessUpdate', 2000)
        // alert(this.$i18n.t('AccountEU.alertEUSuccessUpdate'))
      } catch (err) {
        logger.error(err)
        this.alertSnackBar('AccountEU.alertEUFailedUpdate', -1)
        // alert(this.$i18n.t('AccountEU.alertEUFailedUpdate'))
      }
    }
  }

  export default EndUserMyAccount
</script>
